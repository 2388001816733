import React from 'react'
import { graphql } from 'gatsby'
import { Layout, SEO } from '../components'
import { Container, Button, Row, Col } from 'react-bootstrap'

export const query = graphql`
  query GalleryQuery {
    prismicGallery {
      data {
        images {
          image {
            url
            alt
          }
        }
      }
    }
  }
`

const Gallery = ({ data }) => {
  const block = 'huis-gallery'
  const gallery =
    (data &&
      data.prismicGallery &&
      data.prismicGallery.data &&
      data.prismicGallery.data.images) ||
    []

  const imagesPerCol = 3
  const numOfCols = 4

  const columnImageLists = []
  for (let col = 0; col < numOfCols; col++) {
    columnImageLists.push(
      gallery.slice(col * imagesPerCol, col * imagesPerCol + imagesPerCol)
    )
  }

  return (
    <Layout pageInfo={{ pageName: 'gallery' }}>
      <SEO title="Gallery | Huis Shave Ice" />
      <h1 className="sr-only">Image Gallery</h1>
      <Container fluid className="huis-gallery">
        <Row>
          {columnImageLists &&
            columnImageLists.map((columnImages, index) => {
              return (
                <Col sm={6} md={6} lg={3} key={index}>
                  {columnImages &&
                    columnImages.map(({ image }, index) => {
                      return (
                        <img
                          key={index}
                          src={image.url}
                          alt={image.alt || ''}
                          width="100%"
                          className={`${block}__image`}
                        />
                      )
                    })}
                </Col>
              )
            })}
        </Row>
        <Row className="mt-4 mb-4">
          <Col
            xs={{ span: 8, offset: 2 }}
            sm={{ span: 6, offset: 3 }}
            md={{ span: 4, offset: 4 }}
            lg={{ span: 2, offset: 5 }}
          >
            <Button
              className="btn-block"
              target="_blank"
              href="https://www.instagram.com/huisshaveice/"
              onClick={() => {
                typeof window !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_category: 'outbound',
                    event_label: 'https://www.instagram.com/huisshaveice/',
                    value: 'gallery instagram page',
                  })
              }}
            >
              See More ...
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Gallery
